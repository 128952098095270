import { useQuery, useQueryClient } from "react-query";
import { API_URL, Team } from "types";

const LIST_TEAMS_QUERY = "list-teams";
const LIST_TEAMS_PATH = "/teams/get-teams";

const DEMO_TEAM_NAME = "decisionLab_dev_team";

export const useTeams = ({ signout }: { signout?: () => void }) => {
  const queryClient = useQueryClient();

  const {
    isLoading,
    error,
    data = [],
    isRefetching,
  } = useQuery(
    LIST_TEAMS_QUERY,
    async () =>
      fetch(`${API_URL}${LIST_TEAMS_PATH}`, { credentials: "include" })
        .then((response) => {
          if (!response.ok) throw response;
          return response.json();
        })
        .then((r) => {
          const teams = r.teams.map((team: any) => {
            return team;
          }) as Team[];

          return teams;
        })
        .catch((e) => {
          if (e.status === 401) {
            queryClient.invalidateQueries(LIST_TEAMS_QUERY);
            !!signout && signout();
          }
        }),

    { refetchOnWindowFocus: false }
  );

  // will be used to bypass onboarding
  const teamsWithoutDemoTeam =
    data?.filter(
      (team: { name: string }) => !team.name.includes(DEMO_TEAM_NAME)
    ) || [];

  const hasOneTeam = teamsWithoutDemoTeam.length >= 1;
  const hasManyTeams = teamsWithoutDemoTeam.length > 1;

  return {
    teams: data,
    isLoading,
    error,
    isRefetching,
    hasOneTeam,
    hasManyTeams,
  };
};

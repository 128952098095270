import React, { useRef, useEffect } from "react";

function useOutsideAlerter(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  handleOutsideClick: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        handleOutsideClick();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function ClickOutside({
  children,
  handleOutsideClick,
  className,
  role,
}: {
  children: React.ReactNode;
  handleOutsideClick: () => void;
  className?: string;
  role?: string;
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleOutsideClick);

  return (
    <div ref={wrapperRef} className={className} role={role}>
      {children}
    </div>
  );
}

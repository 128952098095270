export type DecisionComment = {
  createdAt: string;
  author: string;
  comment: string;
};

export type Decision = {
  id: number;
  decisionName: string;
  currentValue: string;
  comments: DecisionComment[];
  tags: string[];
};

export type DecisionHistory = {
  timestamp: string;
  value: string;
  versionId: number;
};

export const MARKETING_WEBSITE = "https://justdecision.com/";
export const MAIN_CONTACT_EMAIL = "jose@justdecision.com";
// TODO: Create env file
export const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8080/";

export const DECISIONS_QUERY = "decisions";
export const DECISIONS_GET_PATH = "v1/decisions";
export const PROMPTS_GET_PATH = "v1/prompts";
export const DECISIONS_POST_PATH = "v1/decision";
export const DECISIONS_TEAM_POST_PATH = "create_team_decision";
export const DECISIONS_PUT_PATH = "v1/decision";
export const DECISIONS_DELETE_PATH = "v1/decision";

export const TEAM_DECISIONS_GET_PATH = "team_decisions/";

export const DECISION_QUERY = "decision";
export const DECISION_GET_PATH = "v1/decisions";
export const IS_PREMIUM_USER_QUERY = "v1/user/is_premium_user";

type DecisionCommentIn = {
  author: string;
  comment: string;
  created_at: string;
};

export type DecisionIn = {
  id: number;
  decision_name: string;
  current_value: string;
  created_timestamp: string;
  comments?: DecisionCommentIn[];
  tags: string[];
};

export enum DecisionType {
  Prompt = "prompt",
  Teams = "teams",
  Decision = "decision",
}

export type DecisionOut = {
  decisionName: string;
  value: string;
  comments?: string[];
  tags: string[];
  teamId?: number;
};

export enum TeamMemberStatus {
  Active = "active",
  Pending = "pending",
}

export type TeamMember = {
  email: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
  role: "admin" | "member";
  username: string;
  status: TeamMemberStatus;
};

export type Notification = {
  id: number;
  is_read: boolean;
  message: string;
  timestamp: string;
  title: string;
};

export type NotificationsProps = {
  notifications: Notification[];
};

export const transformCommentsIn = (comments: DecisionCommentIn[]) => {
  return comments.map((comment) => {
    return {
      author: comment.author,
      comment: comment.comment,
      createdAt: comment.created_at,
    };
  });
};

export const transformDecisionIn = ({
  id,
  decision_name,
  current_value,
  comments,
  tags,
}: DecisionIn): Decision => {
  return {
    id,
    decisionName: decision_name,
    currentValue: current_value,
    comments: (comments && transformCommentsIn(comments)) || [],
    tags,
  };
};

export const transformCreateDecisionOut = ({
  comments,
  decisionName,
  tags,
  value,
  teamId,
}: DecisionOut) => ({
  comments,
  decision_name: decisionName,
  tags,
  value,
  team_id: teamId,
});

export const transformUpdateDecisionOut = ({
  name,
  value,
}: {
  name: string;
  value: string;
}) => ({
  decision_name: name,
  value,
});

export const transformListTeamsIn = ({}) => {
  return {
    hola: 123,
  };
};

export const transformCreateTeamOut = (team: any) => {
  return {
    team_name: team.name,
    description: team.description,
  };
};

export const transformTeamMemberIn = (user: {
  email: string;
  first_name: string;
  last_name: string;
  profile_picture: string;
  role: "admin" | "member";
  username: string;
  status: TeamMemberStatus;
}): TeamMember => {
  return {
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    profilePicture: user.profile_picture,
    role: user.role,
    username: user.username,
    status: user.status,
  };
};

export type Team = {
  id: number;
  name: string;
  description: string;
};

export const getDecisionType = (teamName?: string) => {
  if (!teamName) {
    return DecisionType.Teams;
  }

  return teamName.includes("team_for_extension_")
    ? DecisionType.Prompt
    : DecisionType.Teams;
};

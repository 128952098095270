import React from "react";
import classNames from "classnames";

export const PageHead = ({
  children,
  title,
  actions,
  className,
}: {
  children?: React.ReactNode;
  title: string;
  actions?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={classNames("flex w-full justify-between", className)}>
      <h2 className="scroll-m-20 text-xl font-semibold items-center tracking-normal">
        {title}
      </h2>
      {actions && <span>{actions}</span>}
    </div>
  );
};

import React from "react";
import { Input } from "components/ui/input";

export const Searchbox = ({
  value,
  handleChange,
  placeholder,
  className,
  disabled,
}: {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <Input
      id="search"
      name="search"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
    />
  );
};

import React from "react";

import { TeamMember } from "types";

import { SidebarSection } from "components/MainAndSidebarLayout";
import { LayoutSidebar } from "components/LayoutSidebar";
import { UiTitle } from "pages/Onboarding";
import { GetStarted } from "../../components/GetStarted";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { MAIN_BORDER_COLOR_TAILWIND } from "../../components/Variables";
import { Button } from "../../components/ui/button";
import { SidebarMembers } from "./SidebarMembers";

export const Sidebar = ({
  search,
  handleSearchChange,
  isLoading,
  isRefetching,
  filteredTeamMembers,
  setTeamMembers,
  isSelectedTeam,
  handleSetAsCurrentTeamButton,
  teamName,
  showSidebar,
}: {
  search: string;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  isRefetching: boolean;
  filteredTeamMembers: TeamMember[];
  setTeamMembers: React.Dispatch<React.SetStateAction<TeamMember[]>>;
  isSelectedTeam: boolean;
  handleSetAsCurrentTeamButton: () => void;
  teamName?: string;
  showSidebar: boolean;
}) => {
  const { teamId } = useParams();

  return (
    <LayoutSidebar hidden={!showSidebar}>
      <SidebarMembers
        search={search}
        handleSearchChange={handleSearchChange}
        filteredTeamMembers={filteredTeamMembers}
        setTeamMembers={setTeamMembers}
      />
    </LayoutSidebar>
  );
};

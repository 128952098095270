import React from "react";

import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Field } from "components/Field";
import { Button } from "components/ui/button";
import { useCreateDecision } from "components/CreateDecision";
import { Team } from "types";

export const CreateDecisionModal = ({
  onClose,
  teamId,
}: {
  onClose: () => void;
  teamId?: Team["id"];
}) => {
  const { createDecisionForm, mutation } = useCreateDecision({
    teamId: teamId,
    onSuccessCallback: onClose,
  });

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle className="select-none">New Decision</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>
      <div className="flex items-center space-x-2">
        <form
          onSubmit={createDecisionForm.handleSubmit}
          className="grid flex-1 gap-4 mt-4"
        >
          <Field
            id="decisionName"
            name="decisionName"
            onChange={createDecisionForm.handleChange}
            value={createDecisionForm.values.decisionName}
            label="Decision Name"
            errors={createDecisionForm.errors}
            className="max-w-full"
            helpText="Must be unique and will be used to identify the decision. Only letters, numbers, _, and - are allowed. "
          />
          <Field
            id="value"
            name="value"
            onChange={createDecisionForm.handleChange}
            value={createDecisionForm.values.value}
            label="Decision Value"
            errors={createDecisionForm.errors}
            className="max-w-full"
            helpText="This can be any text, a URL, or a numeric value."
          />

          <div className="flex justify-between w-full mt-4">
            <Button
              type="submit"
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
            >
              Continue
            </Button>

            {onClose && (
              <Button type="button" onClick={onClose} variant="outline">
                Cancel
              </Button>
            )}
          </div>
        </form>
      </div>
    </DialogContent>
  );
};

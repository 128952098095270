export enum ROUTES {
  Main = "/",
  Root = "/",
  Login = "/login",
  Logout = "/logout",
  Dashboard = "/dashboard",
  Decisions = "/decisions",
  DecisionDetail = "/decisions/:decisionId",
  Projects = "/projects",
  Project = "/projects/:teamId",
  CreateTeam = "/projects/create",
  MyAccount = "/account",
  Onboarding = "/onboarding",
  HowToUse = "/setup-instructions",
  Gateway = "/gateway",
}

export const RouteDescriptions = [{}];

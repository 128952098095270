import { useQuery } from "react-query";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { useDecision } from "decisionlab";
import { ROUTES } from "../routes";
import { useAuth } from "./Auth";
import {
  BackpackIcon,
  EnterIcon,
  MixIcon,
  StarFilledIcon,
  BellIcon,
  SunIcon,
} from "@radix-ui/react-icons";
import { Notifications } from "./Notification";
import axios from "axios";
import { API_URL, Notification, IS_PREMIUM_USER_QUERY } from "../types";
import { cn } from "lib/utils";

const MenuItem = ({
  to,
  label,
  img,
  icon,
}: {
  to: string;
  label: string;
  img?: string | null;
  icon?: React.ReactNode | null;
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(
          "flex items-center p-3 text-slate-600 whitespace-nowrap box-border rounded-md text-xs",
          "[&>svg]:inline-block [&>svg]:w-4 [&>svg]:h-4 [&>svg]:mr-2",
          "hover:bg-gray-100",
          {
            "!text-slate-800": isActive,
          }
        )
      }
    >
      {icon && icon}
      {label}

      {img && (
        <img
          alt={label}
          src={img}
          className="inline-block ml-2 h-6 w-6 rounded-full overflow-hidden outline outline-offset-2 outline-1 outline-stone-300 hover:outline-blue-700"
        />
      )}
    </NavLink>
  );
};

const OpenMenuButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      data-collapse-toggle="navbar-default"
      type="button"
      className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
      aria-controls="navbar-default"
      aria-expanded="false"
      onClick={onClick}
    >
      <svg
        className="w-6 h-6"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clipRule="evenodd"
        ></path>
      </svg>
    </button>
  );
};

const useMenuItems = () => {
  const { user } = useAuth();
  const showDecisionsPage = useDecision("show_decisions_page") === true;
  const useIsPremiumUser = () => {
    return useQuery(
      IS_PREMIUM_USER_QUERY,
      async () => {
        return axios
          .get(`${API_URL}/${IS_PREMIUM_USER_QUERY}`, { withCredentials: true })
          .then((response) => response.data.is_premium);
      },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      }
    );
  };
  const { data: isPremiumUser, isLoading, isError } = useIsPremiumUser();

  const menuDecisions = {
    to: ROUTES.Decisions,
    label: "Decisions",
    requiresAuth: true,
    icon: <MixIcon />,
    img: null,
  };

  const menuTeam = {
    to: ROUTES.Projects,
    label: "Teams",
    requiresAuth: true,
    icon: <BackpackIcon />,
    img: null,
  };

  const menuPremium = {
    to: "",
    label: "Premium",
    requiresAuth: true,
    icon: <StarFilledIcon />,
    img: null,
    tooltip: "You are a premium user! Thanks for supporting DecisionLab!",
  };

  const menuItems = [
    ...(showDecisionsPage ? [menuDecisions] : []),
    ...[menuTeam],
    ...(isPremiumUser && !isLoading && !isError ? [menuPremium] : []),
    {
      to: ROUTES.Login,
      label: "Login",
      requiresAuth: false,
      icon: <EnterIcon />,
      img: null,
    },
    {
      to: ROUTES.MyAccount,
      label: "My account",
      requiresAuth: true,
      icon: null,
      img: user?.picture,
    },
  ];

  return {
    menuItems: menuItems.filter((item) => item.requiresAuth === Boolean(user)),
  };
};

const LOGO = "https://justdecision.com/decisionlab.svg";
const NOTIFICATIONS_PATH = "/notifications";

const LeftMenuItem = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  return (
    <NavLink
      className={({ isActive }) =>
        cn(
          "transition-colors hover:text-foreground/80 text-foreground/60 text-sm",
          {
            "text-accent-foreground font-semibold": isActive,
          }
        )
      }
      to={href}
    >
      {children}
    </NavLink>
  );
};

export const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const { menuItems } = useMenuItems();

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await getNotifications();
      setNotifications(response.data);
      const unreadNotifications = response.data.filter(
        (notification) => !notification.is_read
      );
      setNotificationCount(unreadNotifications.length);
    };

    fetchNotifications();
  }, []);

  const getNotifications = async () => {
    return axios.get<Notification[]>(`${API_URL}${NOTIFICATIONS_PATH}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const markNotificationAsRead = async (notificationId: number) => {
    return await axios.post(
      `${API_URL}${NOTIFICATIONS_PATH}/mark-as-read`,
      {
        notification_id: notificationId,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  return (
    <>
      <header className="sticky top-0 z-50 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="flex px-6 h-14 items-center">
          <div className="mr-4 hidden md:flex">
            <a className="mr-6 flex items-center space-x-2" href="/">
              <span className="font-bold sm:inline-block">DecisionLab</span>
            </a>
            <nav className="flex items-center gap-4 text-sm lg:gap-6">
              <LeftMenuItem href={ROUTES.Projects}>Projects</LeftMenuItem>
              <LeftMenuItem href={ROUTES.MyAccount}>My account</LeftMenuItem>
              <LeftMenuItem href={ROUTES.HowToUse}>
                Getting Started
              </LeftMenuItem>
            </nav>
          </div>
          <div className="flex flex-1 items-center justify-between space-x-2 md:justify-end">
            <nav className="flex items-center gap-2">
              <button
                className="!hidden inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 py-2 w-9 px-0"
                type="button"
                aria-haspopup="menu"
                aria-expanded="false"
                data-state="closed"
              >
                <SunIcon className="w-5 h-5" />
                <span className="sr-only">Toggle theme</span>
              </button>
              <button
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 py-2 w-9 px-0"
                type="button"
                aria-haspopup="menu"
                aria-expanded="false"
                data-state="closed"
                onClick={() => {
                  notifications.forEach((notification) => {
                    if (!notification.is_read) {
                      markNotificationAsRead(notification.id);
                    }
                  });
                  setNotificationCount(0);
                  setShowNotifications(!showNotifications);
                }}
              >
                <BellIcon className="w-5 h-5" />
                <span className="sr-only">Notification icon</span>
              </button>
              <LeftMenuItem href={ROUTES.Logout}>Logout</LeftMenuItem>
            </nav>
          </div>
        </div>
      </header>
      {showNotifications && (
        <div className="relative">
          <div className="absolute z-10 top-[1.3px] left-[50%] md:left-auto md:right-[9px] transform translate-y-1/2 -translate-x-1/2 w-2 h-4 bg-white border-t border-gray-900 rotate-45"></div>
          <div
            className={`absolute z-10 w-full top-4 right-0 bg-white rounded-lg p-4 shadow-md ${
              notifications.length === 1 ? " h-auto" : "h-64 overflow-y-auto"
            }`}
          >
            <div className="mb-4">
              <h2 className="font-semibold">Notifications</h2>
            </div>
            <Notifications notifications={notifications} />
          </div>
        </div>
      )}
    </>
  );
};

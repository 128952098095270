import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { Loading } from "components/Loading";
import { Empty } from "components/Empty";
import { ROUTES } from "routes";
import { Table } from "components/Table";
import { Dialog } from "components/ui/dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { useTeams } from "hooks/useTeams";
import { CreateProjectModal } from "./projects/CreateProjectModal";
import { PageHead } from "components/PageHead";
import { useAuth } from "components/Auth";

export const ProjectsPage = () => {
  const navigate = useNavigate();
  const { setSelectedTeam } = useAuth();
  const { teams, isLoading, isRefetching } = useTeams({});
  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleTeamClick = (teamId: number) => {
    const team = teams.find((team) => team.id === teamId);
    if (team) {
      setSelectedTeam(team);
      navigate(`${ROUTES.Projects}/${teamId}`);
    }
  };

  const teamsTable = teams.length ? (
    <Table
      isRefetching={isRefetching}
      columnNames={["Project", "Description"]}
      data={teams}
      renderRow={(team) => {
        return (
          <tr
            className="bg-white border-t hover:bg-gray-50 cursor-pointer"
            key={team.name}
            onClick={() => handleTeamClick(team.id)}
          >
            <td className="px-6 py-4">{team.name}</td>
            <td className="px-6 py-4">{team.description}</td>
          </tr>
        );
      }}
    />
  ) : (
    <Empty title="You don't have any teams" description="" />
  );

  return (
    <div>
      <Dialog>
        <div className="overflow-x-auto container mx-auto mt-12">
          <PageHead
            title="Projects"
            actions={
              <DialogTrigger asChild>
                <Button onClick={() => setShowCreateModal(true)}>
                  Create Project
                </Button>
              </DialogTrigger>
            }
            className="mb-4"
          />
          {(isLoading && <Loading />) || teamsTable}
          {showCreateModal && (
            <CreateProjectModal onClose={() => setShowCreateModal(false)} />
          )}
        </div>
      </Dialog>
    </div>
  );
};

import { Button } from "components/Button";
import React from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFormik,
  FormikErrors,
  FormikProps,
  FormikFormProps,
  Form,
  FormikValues,
} from "formik";
import axios, { AxiosError } from "axios";
import * as yup from "yup";

import {
  API_URL,
  Decision,
  DecisionIn,
  DecisionOut,
  DECISION_QUERY,
  transformDecisionIn,
  transformCreateDecisionOut,
  DECISION_GET_PATH,
  DECISIONS_PUT_PATH,
} from "../types";

import { Field } from "./Field";
import { useDecisionComments } from "./DecisionComments";
import { ChatBubbleIcon } from "@radix-ui/react-icons";

export const AddCommentForm = ({
  decision,
  refetchDecision,
}: {
  decision: Decision;
  refetchDecision: () => void;
}) => {
  const { addCommentForm } = useDecisionComments({
    decisionId: decision.id,
    refetchDecision,
  });
  return (
    <form onSubmit={addCommentForm.handleSubmit} className="mt-4">
      <Field
        id="comment"
        name="comment"
        onChange={addCommentForm.handleChange}
        value={addCommentForm.values.comment}
        placeholder="Add new comment..."
        errors={addCommentForm.errors}
      />
      <Button
        type="submit"
        disabled={addCommentForm.isSubmitting}
        loading={addCommentForm.isSubmitting}
        icon={<ChatBubbleIcon />}
        className="mt-4"
      >
        Add comment
      </Button>
    </form>
  );
};

import React from "react";
import classNames from "classnames";
import { useState } from "react";
import { ClickOutside } from "./ClickOutside";

const ContextMenuItem = ({
  onClick,
  children,
  isNegative,
}: {
  onClick: () => void;
  children: React.ReactNode;
  isNegative?: boolean;
}) => (
  <button
    className={classNames(
      "block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700",
      {
        "text-red-700": isNegative,
      }
    )}
    role="menuitem"
    onClick={onClick}
  >
    {isNegative && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 inline-block mr-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
        />
      </svg>
    )}
    {children}
  </button>
);

export const ContextMenu = ({
  actions,
}: {
  actions: { label: string; onClick: () => void; isNegative?: boolean }[];
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuItems = showMenu && (
    <ClickOutside
      handleOutsideClick={() => setShowMenu(false)}
      className="absolute right-0 top-8 z-10 mt-2 w-56 rounded-md border border-gray-100 bg-white shadow-lg select-none"
      role="menu"
    >
      <div className="p-2 mt-0">
        {actions.map((action) => (
          <ContextMenuItem
            key={action.label}
            onClick={action.onClick}
            isNegative={action.isNegative}
          >
            {action.label}
          </ContextMenuItem>
        ))}
      </div>
    </ClickOutside>
  );
  return (
    <div className="relative">
      <div className="inline-flex items-center divide-x divide-gray-100 overflow-hidden rounded-md border bg-white text-[0px]">
        {/* <a
            href="#"
            className="px-4 py-2 text-sm leading-none text-gray-600 hover:bg-gray-50 hover:text-gray-700"
          >
            Edit
          </a> */}
        <button
          className="h-full p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700"
          onClick={() => setShowMenu(!showMenu)}
        >
          <span className="sr-only">Menu</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {menuItems}
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { GetStarted } from "components/GetStarted";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useTeams } from "hooks/useTeams";
import { Team } from "types";

export const HowToUse = () => {
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const { teams } = useTeams({});

  console.log({ selectedTeam });

  return (
    <div className="container mt-12">
      Select a project to continue
      {teams.length > 0 && (
        <Select
          onValueChange={(teamName) => {
            const team = teams.find((team) => team.name === teamName);
            if (team) {
              setSelectedTeam(team);
            }
          }}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Project" />
          </SelectTrigger>
          <SelectContent>
            {teams.map((team) => (
              <SelectItem
                key={team.id}
                value={team.name}
                onClick={() => setSelectedTeam(team)}
              >
                {team.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
      {!!selectedTeam && (
        <GetStarted teamId={Number(selectedTeam.id)} className="md:visible" />
      )}
    </div>
  );
};

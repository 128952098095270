import React from "react";
import styled from "styled-components";

import { useAuth } from "./Auth";
import {
  APP_MENU_HEIGHT,
  CONTAINER_HORIZONTAL_PADDING,
  MAIN_BORDER_COLOR,
} from "./Variables";
import { Menu } from "./Menu";
import { Outlet } from "react-router-dom";

const StyledAppLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  #app_menu {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    height: ${APP_MENU_HEIGHT}px;
    min-height: ${APP_MENU_HEIGHT}px;
    border-bottom: 1px solid ${MAIN_BORDER_COLOR};
    padding-left: ${CONTAINER_HORIZONTAL_PADDING}px;
    padding-right: ${CONTAINER_HORIZONTAL_PADDING}px;
  }

  #app_outlet {
    display: block;
    box-sizing: content-box;
    height: calc(100vh - ${APP_MENU_HEIGHT}px);
    max-height: calc(100vh - ${APP_MENU_HEIGHT}px);

    overflow-y: auto;
    overflow-x: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  #app_outlet::-webkit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: none;
    width: 0;
    height: 0;
  }
`;

export const AppLayout = ({ children }: { children?: React.ReactNode }) => {
  const auth = useAuth();

  return (
    <StyledAppLayout>
      {auth.user ? <Menu /> : null}
      <div id="app_outlet">
        <Outlet />
        {children}
      </div>
    </StyledAppLayout>
  );
};

import React from "react";
import classNames from "classnames";
import styled from "styled-components";

import { useCreateTeam } from "../hooks/useCreateTeam";
import { Field } from "components/Field";
import { Button } from "components";
import { Cross2Icon, MagicWandIcon } from "@radix-ui/react-icons";

export const DecisionLabUiTitle = ({
  children,
  className,
}: {
  children: string;
  className?: string;
}) => {
  return (
    <h1
      className={classNames("text-2xl font-bold select-none mb-4", className)}
    >
      {children}
    </h1>
  );
};

export const StyledDecisionLabUiDisclaimer = styled.p`
  font-family: "Inter var", "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #374151;
  cursor: default;
  user-select: none;
`;

export const UiTitle = ({
  children,
  as = "h1",
  className,
  style,
  sub,
}: {
  children: string;
  as?: React.ElementType;
  className?: string;
  style?: React.CSSProperties;
  sub?: string;
}) => {
  return (
    <>
      <div
        className={classNames(
          "flex justify-between items-center h-8",
          className
        )}
      >
        <h2 className="tracking-tight text-base font-medium select-none">
          {children}
        </h2>
      </div>
      {sub && (
        <StyledDecisionLabUiDisclaimer className="mb-4">
          {sub}
        </StyledDecisionLabUiDisclaimer>
      )}
    </>
  );
};

const Onboarding = () => {
  // as of 2023-10-02, userCompletedOnboarding is defined as
  // - [x] user has created a team
  const userCompletedOnboarding = false;
  const { createTeamForm, mutation, handleCancelClick } = useCreateTeam();

  return (
    <div className="flex align-middle justify-center h-100">
      <div
        style={{
          width: "100%",
          maxWidth: "550px",
        }}
      >
        <DecisionLabUiTitle>
          Welcome to DecisionLab, to get started create your 1st team.
        </DecisionLabUiTitle>
        <p>
          You don't have to invite anyone to your team at this time, but
          decisions are namespaced under your team.
        </p>
        <div className="container mx-auto py-12">
          <h1 className="text-2xl font-bold select-none">Create Team</h1>
          <form onSubmit={createTeamForm.handleSubmit}>
            <Field
              id="name"
              name="name"
              onChange={createTeamForm.handleChange}
              value={createTeamForm.values.name}
              label="Team Name"
              errors={createTeamForm.errors}
            />
            <Field
              id="description"
              name="description"
              onChange={createTeamForm.handleChange}
              value={createTeamForm.values.description}
              label="Description"
              errors={createTeamForm.errors}
            />
            <div className="mt-12">
              <Button type="submit" loading={mutation.isLoading}>
                <MagicWandIcon className="w-4 h-4 mr-2" />
                Create team
              </Button>
              <Button type="button" onClick={handleCancelClick} muted>
                <Cross2Icon className="w-4 h-4 mr-2" />
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;

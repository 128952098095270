import React from "react";

import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Button } from "./ui/button";
import { TeamMember } from "types";

export const DeleteMemberConfirmModal = ({
  member,
  onSuccessCallback,
  onCancelClick,
  handleRemoveUserClick,
}: {
  member: TeamMember;
  onSuccessCallback?: () => void;
  onCancelClick?: () => void;
  handleRemoveUserClick?: () => void;
}) => {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle className="select-none">Are you sure?</DialogTitle>
        <DialogDescription className="select-none">
          Are you sure you want to delete {member.firstName} {member.lastName}?
        </DialogDescription>
      </DialogHeader>
      <div className="flex items-center space-x-2">
        <form
          onSubmit={handleRemoveUserClick}
          className="grid flex-1 gap-2 mt-4"
        >
          <div className="flex justify-between w-full mt-4">
            <Button type="submit" variant={"destructive"}>
              Remove user
            </Button>

            {onCancelClick && (
              <Button
                type="button"
                style={{ margin: "0px" }}
                onClick={onCancelClick}
                variant="outline"
              >
                Cancel
              </Button>
            )}
          </div>
          <p className="mt-2 text-sm text-neutral-500 cursor-default select-none">
            This action cannot be undone.
          </p>
        </form>
      </div>
    </DialogContent>
  );
};

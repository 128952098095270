import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { NotificationsProps } from "../types";

export const Notifications = ({ notifications }: NotificationsProps) => {
  const removeHttpLink = (message: string) => {
    const httpIndex = message.indexOf("http://");
    const httpsIndex = message.indexOf("https://");
    let actualIndex = -1;

    if (httpIndex !== -1) {
      actualIndex = httpIndex;
    } else if (httpsIndex !== -1) {
      actualIndex = httpsIndex;
    }

    if (actualIndex !== -1) {
      const spaceIndex = message.indexOf(" ", actualIndex);
      if (spaceIndex !== -1) {
        const url = message.substring(actualIndex, spaceIndex);
        const updatedMessage = message.replace(url, "");
        return { url, updatedMessage };
      }
    }

    return { url: "", updated_message: message };
  };

  const formatRelativeTime = (timestamp: string) => {
    const currentTime = new Date();
    const notificationTime = new Date(timestamp);
    const timeDifference = currentTime.getTime() - notificationTime.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    }
  };

  return (
    <>
      {notifications.length === 0 ? (
        <p>No notifications to display.</p>
      ) : (
        notifications.map((notification) => (
          <div key={notification.id}>
            <div
              className={` mb-3 pb-3 ${
                notification.id === notifications[notifications.length - 1].id
                  ? "border-b-0"
                  : "border-b"
              }`}
            >
              <p className="text-sm font-semibold mb-2">{notification.title}</p>
              <p className="mb-2">
                <a
                  className="text-blue-500 text-sm"
                  href={removeHttpLink(notification.message).url}
                >
                  Click to join the team
                </a>
              </p>
              <p className="text-gray-500 text-xs">
                {formatRelativeTime(notification.timestamp)}
              </p>
            </div>
          </div>
        ))
      )}
    </>
  );
};

import React, { useState } from "react";
import styled from "styled-components";

import { TeamMember } from "types";
import { CaretDownIcon, CaretRightIcon } from "@radix-ui/react-icons";

import { SidebarSection } from "components/MainAndSidebarLayout";
import { useTeamMember } from "hooks/useTeamMember";
import { Badge } from "../../components/ui/badge";
import classNames from "classnames";
import { AddMemberModal } from "../../components/AddMemberModal";
import { Dialog, DialogTrigger } from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { DeleteMemberConfirmModal } from "../../components/DeleteMemberConfirmModal";
import { useAuth } from "components/Auth";
import { Plus } from "@phosphor-icons/react";

export const StyledTeamMemberRowNew = styled.div`
  font-family: "Inter var", "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #164e63;
  user-select: none;
  padding: 12px 16px;
  box-sizing: border-box;
  /* background-color: #f4f4f5; */
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: default;

  &:hover {
    background-color: #f4f4f5;
    border-color: #e4e4e7;
  }

  .main_title {
    font-weight: 500;
  }

  .descriptive_value {
    font-family: "IBM Plex Mono", sans-serif;
    font-size: 11px;
  }

  & + & {
    margin-top: 8px;
  }
`;

const TeamMemberDiv = ({
  user,
  setTeamMembers,
}: {
  user: TeamMember;
  setTeamMembers: React.Dispatch<React.SetStateAction<TeamMember[]>>;
}) => {
  const auth = useAuth();

  const {
    showMore,
    setShowMore,
    isPending,
    isPositive,
    handleRemoveClick,
    setShowRemoveMemberModal,
    showRemoveMemberModal,
  } = useTeamMember({ user, setTeamMembers });

  const fullName = `${user.firstName} ${user.lastName}`;
  const email = user.email;

  const isCurrentUser = auth.user?.email === user.email;

  return (
    <>
      <div className="py-4 border-b last-of-type:border-0 cursor-default">
        <div className="flex items-center">
          <div className="flex-1">
            <h3 className="text-sm">
              {fullName}
              <Badge
                className="ml-2 font-normal"
                {...(isPending
                  ? {
                      variant: "secondary",
                    }
                  : {})}
              >
                {isPending && "Pending"}
                {!isPending && user.role}
              </Badge>
            </h3>
            <p className="text-xs tracking-wide text-zinc-500">{email}</p>
          </div>
          <Button
            size="iconSmall"
            onClick={() => setShowMore((prev) => !prev)}
            className={classNames({ hidden: isCurrentUser })}
            variant="ghost"
          >
            {showMore ? (
              <CaretDownIcon width={18} height={18} />
            ) : (
              <CaretRightIcon width={18} height={18} />
            )}
          </Button>
        </div>

        {showMore && !isCurrentUser && (
          <DialogTrigger asChild>
            <Button
              className="mt-2"
              onClick={() => setShowRemoveMemberModal(true)}
              variant="destructive"
              size="xs"
            >
              Remove user
            </Button>
          </DialogTrigger>
        )}
      </div>
      {showRemoveMemberModal && (
        <>
          <DeleteMemberConfirmModal
            member={user}
            handleRemoveUserClick={handleRemoveClick}
            onSuccessCallback={() => setShowRemoveMemberModal(false)}
            onCancelClick={() => setShowRemoveMemberModal(false)}
          />
        </>
      )}
    </>
  );
};

export const SidebarMembers = ({
  search,
  handleSearchChange,
  filteredTeamMembers,
  setTeamMembers,
}: {
  search: string;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filteredTeamMembers: TeamMember[];
  setTeamMembers: React.Dispatch<React.SetStateAction<TeamMember[]>>;
}) => {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  return (
    <>
      <SidebarSection>
        <Dialog>
          <div className="flex justify-between items-center">
            <h2 className="tracking-tight text-base font-normal select-none">
              Members
            </h2>
            <DialogTrigger asChild>
              <Button
                onClick={() => setShowAddMemberModal(true)}
                size="xs"
                icon={<Plus weight="bold" />}
              >
                Add member
              </Button>
            </DialogTrigger>
          </div>
          {showAddMemberModal && (
            <AddMemberModal
              onSuccessCallback={() => setShowAddMemberModal(false)}
              onCancelClick={() => setShowAddMemberModal(false)}
            />
          )}
        </Dialog>
        <Dialog>
          {filteredTeamMembers.map((member) => (
            <TeamMemberDiv user={member} setTeamMembers={setTeamMembers} />
          ))}
        </Dialog>
      </SidebarSection>
    </>
  );
};

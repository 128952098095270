import React from "react";
import { Decision } from "types";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { Badge } from "./ui/badge";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes";
import CopyToClipboard from "pages/CopyToClipboard";
import classNames from "classnames";

export const DecisionList = ({
  loading,
  decisions,
  handleTagClick,
}: {
  loading: boolean;
  decisions: Decision[];
  handleTagClick: (tag: Decision["tags"][0]) => void;
}) => {
  const navigate = useNavigate();

  const handleViewDecisionClick = (d: Decision) => {
    navigate(`${ROUTES.Decisions}/${d.id}`);
  };

  return (
    <Table className="table-fixed">
      <TableHeader>
        <TableRow>
          <TableHead className="">Decision Name</TableHead>
          <TableHead className="">Value</TableHead>
          <TableHead className={classNames("text-right hidden md:table-cell")}>
            Tags
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {decisions.map((decision) => (
          <TableRow onClick={() => handleViewDecisionClick(decision)}>
            <TableCell className="font-medium text-ellipsis overflow-hidden whitespace-nowrap">
              {decision.decisionName}
            </TableCell>
            <TableCell className="text-ellipsis overflow-hidden whitespace-nowrap">
              {decision.currentValue}
              <CopyToClipboard text={decision.currentValue}></CopyToClipboard>
            </TableCell>
            <TableCell
              className={classNames("text-right hidden md:table-cell")}
            >
              <div className="flex gap-1 justify-end">
                {decision.tags.map((tag) => (
                  <Badge
                    key={tag}
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTagClick(tag);
                    }}
                  >
                    {tag}
                  </Badge>
                ))}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

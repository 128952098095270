import React, { useState } from "react";
import { FormikValues } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import styled from "styled-components";
import { ArrowLeftIcon, Cross2Icon, Pencil2Icon } from "@radix-ui/react-icons";

import { Button } from "components";
import { useDecision } from "components/useDecision";
import { AddDecisionTagForm } from "components/AddDecisionTag";
import { ButtonGroup, Field } from "components/Field";
import { Loading } from "components/Loading";
import { Tag } from "components/Tag";
import {
  API_URL,
  Decision,
  DECISIONS_GET_PATH,
  DecisionType,
  getDecisionType,
} from "types";
import { DecisionComments } from "components/DecisionComments";
import {
  MainAndSidebarLayout,
  SidebarSection,
} from "components/MainAndSidebarLayout";
import { UiTitle } from "./Onboarding";
import {
  CONTAINER_HORIZONTAL_PADDING,
  MAIN_BORDER_COLOR,
  MAIN_BORDER_COLOR_TAILWIND,
} from "components/Variables";
import CopyToClipboard from "pages/CopyToClipboard";
import axios from "axios";
import { LayoutSidebar } from "components/LayoutSidebar";
import classNames from "classnames";
import { Label } from "components/ui/label";
import { CloudDownload } from "lucide-react";
import { useAuth } from "components/Auth";
import { ROUTES } from "routes";

// Luxon does not respect the timezone in the ISO format, so we need to manually set it
// https://github.com/moment/luxon/issues/306
export const getDate = (iso: string): DateTime =>
  DateTime.fromISO(iso, { zone: "utc" }).toLocal();

const StyledDecisionValue = styled.p`
  display: inline-block;
  padding: 8px 16px;
  box-sizing: border-box;
  color: #0ea5e9;
  font-family: "IBM Plex Mono", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  cursor: default;
  letter-spacing: 0.1em;
  background-color: #f3f4f6;
  border-radius: 6px;
`;

const DecisionOverview = ({
  decision,
  showUpdate,
  handleUpdateDecisionClick,
  handleUpdateDecisionCancelClick,
  handleCancelClick,
  updateDecisionForm,
}: {
  decision?: Decision;
  showUpdate: boolean;
  handleUpdateDecisionClick: () => void;
  handleUpdateDecisionCancelClick: () => void;
  handleCancelClick: () => void;
  updateDecisionForm: FormikValues;
}) => {
  if (!decision) return null;

  return (
    <div
      className="py-8"
      style={{
        borderBottom: `1px solid ${MAIN_BORDER_COLOR}`,
        paddingLeft: CONTAINER_HORIZONTAL_PADDING,
        paddingRight: CONTAINER_HORIZONTAL_PADDING,
      }}
    >
      <div className="flex items-center mb-8">
        <UiTitle className="flex-1">Decision Overview</UiTitle>
        <Button onClick={handleCancelClick} muted icon={<ArrowLeftIcon />}>
          Go Back
        </Button>
        {showUpdate ? (
          <Button
            type="button"
            muted
            disabled={updateDecisionForm.isSubmitting}
            onClick={handleUpdateDecisionCancelClick}
            icon={<Cross2Icon />}
          >
            Cancel
          </Button>
        ) : (
          <Button
            type="submit"
            className="mt-0 mb-0 ml-0 mr-0"
            onClick={handleUpdateDecisionClick}
            icon={<Pencil2Icon />}
          >
            Update Decision
          </Button>
        )}
      </div>

      <tr>
        <td className="pb-4">
          <Label>Decision Name</Label>
          <div className="flex items-center justify-between">
            <StyledDecisionValue>{decision.decisionName}</StyledDecisionValue>
            <CopyToClipboard text={decision.decisionName} />
          </div>
          <span className="text-xs text-zinc-500 select-none cursor-default">
            Decision Names cannot be changed, if you need it, create a new
            Decision with the new name you'd like to use.
          </span>
        </td>
      </tr>

      {(!showUpdate && (
        <tr>
          <td>
            <Label>Decision Value</Label>
            <div className="flex items-center justify-between">
              <StyledDecisionValue
                className={classNames(
                  "max-w-3xl max-h-96 overflow-scroll whitespace-normal w-full",
                  {
                    "!text-[10px]": decision.currentValue.length > 120,
                  }
                )}
              >
                {/* try to parse decision.currentValue as JSON first, if it's valid json format as json otherwise just show the regular string */}
                {(() => {
                  try {
                    return (
                      <pre className="w-full">
                        {JSON.stringify(
                          JSON.parse(decision.currentValue),
                          null,
                          2
                        )}
                      </pre>
                    );
                  } catch (e) {
                    return decision.currentValue;
                  }
                })()}
              </StyledDecisionValue>
              <CopyToClipboard text={updateDecisionForm.values.value} />
            </div>
          </td>
        </tr>
      )) || (
        <form onSubmit={updateDecisionForm.handleSubmit}>
          <Field
            id="value"
            name="value"
            onChange={updateDecisionForm.handleChange}
            value={updateDecisionForm.values.value}
            label="Decision Value"
            errors={updateDecisionForm.errors}
            helpText="This can be any text, a URL, or a numeric value."
          />
          <ButtonGroup>
            <Button
              type="submit"
              loading={updateDecisionForm.isSubmitting}
              icon={<Pencil2Icon />}
            >
              Save changes
            </Button>
            <Button
              type="button"
              muted
              disabled={updateDecisionForm.isSubmitting}
              onClick={handleUpdateDecisionCancelClick}
              icon={<Cross2Icon />}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </form>
      )}
    </div>
  );
};

export const ViewDecision = () => {
  const { decisionId = "" } = useParams<{ decisionId: string }>();
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(true);
  const { selectedTeam } = useAuth();
  const decisionType = getDecisionType(selectedTeam?.name);
  const {
    decision,
    isLoading,
    isRefetching,
    addTagForm,
    updateDecisionForm,
    removeTag,
    deleteDecision,
    refetchDecision,
    showUpdate,
    isLoadingHistory,
    history,
    handleUpdateDecisionClick,
    handleUpdateDecisionCancelClick,
    handleCancelClick,
  } = useDecision({ decisionId, decisionType });

  const handleGoToTag = (tag: string) => {
    const teamId = selectedTeam?.id;

    if (!teamId) return;

    const path = ROUTES.Project.replace(":teamId", String(teamId));
    const searchParams = new URLSearchParams();
    searchParams.set("tag", tag);
    navigate({ pathname: path, search: searchParams.toString() });
  };

  const handleDownloadCSV = async () => {
    const apiUrl = `${API_URL}${DECISIONS_GET_PATH}/${decisionId}/csv`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        responseType: "blob",
      });
      const blob = response.data; // Axios stores response content in the data attribute
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "decision.csv"); // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Removed parentNode reference to simplify
    } catch (error) {
      console.error("Download CSV failed", error);
    }
  };

  return (
    <MainAndSidebarLayout
      className={classNames("flex-col md:flex-row h-auto md:h-full box-border")}
    >
      <div className="flex flex-col w-full">
        {isLoading && <Loading fullHeight />}
        <DecisionOverview
          decision={decision}
          showUpdate={showUpdate}
          updateDecisionForm={updateDecisionForm}
          handleUpdateDecisionClick={handleUpdateDecisionClick}
          handleUpdateDecisionCancelClick={handleUpdateDecisionCancelClick}
          handleCancelClick={handleCancelClick}
        />
      </div>

      <LayoutSidebar hidden={!showSidebar}>
        <SidebarSection>
          <UiTitle className="mb-4">Tags</UiTitle>

          <div className="flex gap-4 w-full flex-wrap">
            {decision?.tags.map((tag) => (
              <Tag
                showDelete
                handleDelete={() => removeTag(tag)}
                key={tag}
                onClick={() => handleGoToTag(tag)}
              >
                {tag}
              </Tag>
            ))}
          </div>
          <AddDecisionTagForm
            decision={decision}
            form={addTagForm}
            className="mt-4"
          />
        </SidebarSection>

        <DecisionComments
          decision={decision}
          refetchDecision={refetchDecision}
        />

        {decision && (
          <>
            <SidebarSection>
              <UiTitle className="mb-4">Download Options</UiTitle>

              <Button onClick={handleDownloadCSV} icon={<CloudDownload />}>
                Download CSV
              </Button>
            </SidebarSection>
            {Boolean(Number(history?.length) > 0) && (
              <SidebarSection>
                <UiTitle className="mb-4">History</UiTitle>
                {history?.map((item) => {
                  const timestamp = getDate(item.timestamp);
                  const isoDate = `${timestamp.toISODate()} ${timestamp.toISOTime()}`;

                  return (
                    <div className="flex flex-col border-b last-of-type:border-none py-4">
                      <p className="text-sm whitespace-pre-wrap max-w-fit overflow-hidden h-auto max-h-12 overflow-ellipsis">
                        {item.value}
                      </p>
                      <p className="text-xs text-zinc-400 cursor-default whitespace-nowrap">
                        {isoDate}
                      </p>
                    </div>
                  );
                })}
              </SidebarSection>
            )}
          </>
        )}
      </LayoutSidebar>
    </MainAndSidebarLayout>
  );
};

import React from "react";
import classNames from "classnames";
import { MAIN_BORDER_COLOR_TAILWIND } from "./Variables";
import { appHeight } from "./Common";

export const LayoutSidebar = ({
  className,
  hidden,
  children,
}: {
  className?: string;
  hidden?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "w-full bg-zinc-50 md:max-w-sm md:border-l duration-300 ease-in-out transition-all",
        "h-auto md:h-[calc(100vh-73px)] md:overflow-y-scroll md:overflow-x-hidden no-scrollbar",
        className,
        {
          "!w-0 !overflow-x-hidden": hidden,
        }
      )}
    >
      {children}
    </div>
  );
};

import React, { useState } from "react";
import { useFormik, FormikErrors } from "formik";
import classNames from "classnames";

import { Input } from "components/ui/input";
import { Label } from "./ui/label";

export const Field = <K, T extends keyof K>({
  onChange,
  value,
  id,
  name,
  placeholder,
  label,
  errors,
  helpText,
  className,
  disabled,
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  id: string;
  name: string;
  placeholder?: string;
  label?: string;
  errors: FormikErrors<K>;
  helpText?: string;
  className?: string;
  disabled?: boolean;
}) => {
  const errorKey = Object.getOwnPropertyNames(errors).find(
    (key) => key === id
  ) as T;

  const error = Object.getOwnPropertyNames(errors)?.includes(id) && (
    <p className="text-xs text-red-600">
      <span className="font-medium">{errors[errorKey] as string}</span>
    </p>
  );

  const help = helpText && (
    <p className="text-sm text-gray-500 select-none">{helpText}</p>
  );

  return (
    <div
      className={classNames(
        "grid w-full max-w-sm items-center gap-1.5",
        className
      )}
    >
      {label && <Label htmlFor={name}>{label}</Label>}

      <Input
        type="text"
        className={classNames("w-full", {
          disabled: disabled,
        })}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        id={id}
        name={name}
        disabled={disabled}
      />
      {error}
      {help}
    </div>
  );
};

export const ButtonGroup = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return <div className={classNames("mt-12", className)}>{children}</div>;
};

import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Toaster } from "react-hot-toast";
import { HelmetProvider } from "react-helmet-async";
import { DecisionProvider } from "decisionlab";

import "./globals.css";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { ViewDecision } from "./pages/DecisionDetail";
import { ProjectDashboard } from "./pages/project/ProjectDashboard";
import { Account } from "./pages/Account";

import { AuthProvider, RequireAuth } from "./components/Auth";

import { ROUTES } from "./routes";

import Onboarding from "pages/Onboarding";
import { ProjectsPage } from "pages/Projects";
import { Gateway } from "pages/Gateway";
import { AppLayout } from "components/AppLayout";
import { Toaster as ShadToaster } from "components/ui/toaster";
import { HowToUse } from "pages/HowToUse";

const queryClient = new QueryClient();

const App = () => {
  if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) {
    alert("REACT_APP_GOOGLE_CLIENT_ID is not set in .env file");
    return null;
  }

  if (!process.env.REACT_APP_DECISION_LAB_KEY) {
    alert("REACT_APP_DECISION_LAB_KEY is not set in .env file");
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AuthProvider>
          <HelmetProvider>
            <DecisionProvider
              clientKey={process.env.REACT_APP_DECISION_LAB_KEY}
            >
              <Toaster />
              <ShadToaster />
              <BrowserRouter>
                <Routes>
                  <Route element={<AppLayout />}>
                    <Route path={ROUTES.Login} element={<Login />} />
                    <Route path={ROUTES.MyAccount} element={<Account />} />
                    <Route path={ROUTES.Logout} element={<Logout />} />
                    {/* <Route
                      path={ROUTES.Decisions}
                      element={
                        <RequireAuth>
                          <Decisions decisionType="user" />
                        </RequireAuth>
                      }
                    /> */}
                    <Route
                      path={ROUTES.DecisionDetail}
                      element={
                        <RequireAuth>
                          <ViewDecision />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={ROUTES.Project}
                      element={
                        <RequireAuth>
                          <ProjectDashboard />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={ROUTES.Projects}
                      element={
                        <RequireAuth>
                          <ProjectsPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={ROUTES.Onboarding}
                      element={
                        <RequireAuth>
                          <Onboarding />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={ROUTES.HowToUse}
                      element={
                        <RequireAuth>
                          <HowToUse />
                        </RequireAuth>
                      }
                    />
                    <Route path={ROUTES.Main} element={<Gateway />} />

                    {/* if there are no matching routes, redirect to index */}
                    <Route
                      path="*"
                      element={<Navigate to={ROUTES.Gateway} />}
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
            </DecisionProvider>
          </HelmetProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
};

export default App;

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { useQuery, useMutation, QueryClient } from "react-query";
import axios from "axios";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import {
  API_URL,
  DECISION_QUERY,
  transformDecisionIn,
  DECISION_GET_PATH,
  PROMPTS_GET_PATH,
  DECISIONS_POST_PATH,
  transformUpdateDecisionOut,
  DECISIONS_QUERY,
  DECISIONS_DELETE_PATH,
  Decision,
  DecisionHistory,
  DecisionType,
} from "../types";
import { updateDecisionValidationSchema } from "components/CreateDecision";
import { ROUTES } from "routes";

export const useDecision = ({
  decisionId,
  decisionType = DecisionType.Decision,
}: {
  decisionId: string | number;
  decisionType: DecisionType;
}) => {
  const [showUpdate, setShowUpdate] = useState(false);
  const navigate = useNavigate();
  const queryClient = new QueryClient();

  const handleUpdateDecisionClick = () => {
    setShowUpdate(true);
  };

  const handleUpdateDecisionCancelClick = () => {
    updateDecisionForm.resetForm();
    setShowUpdate(false);
  };

  const {
    isLoading,
    error,
    data,
    refetch: refetchDecision,
    isRefetching,
  } = useQuery(
    DECISION_QUERY,
    async () => {
      const path =
        decisionType === DecisionType.Prompt
          ? PROMPTS_GET_PATH
          : DECISION_GET_PATH;
      return axios
        .get(`${API_URL}${path}/${decisionId}`, {
          withCredentials: true,
        })
        .then((response) => response.data)
        .then((r) => transformDecisionIn(r.decision));
    },
    { refetchOnWindowFocus: false, refetchOnMount: true }
  );

  const {
    isLoading: isLoadingHistory,
    error: errorHistory,
    data: history,
    refetch: refetchHistory,
    isRefetching: isRefetchingHistory,
  } = useQuery("decision_history", async () => {
    return axios
      .get(`${API_URL}v1/decision_history?decision_id=${decisionId}`, {
        withCredentials: true,
      })
      .then((response) => response.data)
      .then((r) => {
        const history = r.history;

        return history.map(
          (entry: {
            edited_timestamp: string;
            old_value: string;
            version_id: number;
          }) => {
            return {
              timestamp: entry.edited_timestamp,
              value: entry.old_value,
              versionId: entry.version_id,
            } as DecisionHistory;
          }
        ) as DecisionHistory[];
      })
      .catch((e) => {
        if (e.response.status === 404) {
          return [] as DecisionHistory[];
        }
        // TODO: throw to sentry or something
        // throw e;
        return [] as DecisionHistory[];
      });
  });

  const updateDecisionCall = async (d: { name: string; value: string }) => {
    return axios.put(
      `${API_URL}${DECISIONS_POST_PATH}/${decisionId}`,
      transformUpdateDecisionOut(d),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const addTagApiCall = async (decision: { tag: string }) => {
    const id = decisionId;
    return axios.post(
      `${API_URL}/v1/tags/${id}`,
      { tags: [decision.tag] },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const deleteDecisionCall = async (decision: Decision) => {
    return await axios.delete(
      `${API_URL}${DECISIONS_DELETE_PATH}/${decisionId}`,
      {
        withCredentials: true,
        data: {
          decision_name: decision.decisionName,
        },
      }
    );
  };

  // TODO:
  // Consider this bit technical debt, delete requests should not have a body
  // https://stackoverflow.com/questions/299628/is-an-entity-body-allowed-for-an-http-delete-request
  // https://stackoverflow.com/questions/51069552/axios-delete-request-with-request-body-and-headers
  const removeTagApiCall = async (decision: { tag: string }) => {
    const id = decisionId;
    return axios.delete(`${API_URL}/v1/tags/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      data: { tags: [decision.tag] },
    });
  };

  const deleteDecisionMutation = useMutation(deleteDecisionCall);
  const updateDecisionMutation = useMutation(updateDecisionCall);
  const addTagMutation = useMutation(addTagApiCall);
  const removeTagMutation = useMutation(removeTagApiCall);

  const addTagForm = useFormik({
    initialValues: { tag: "" },
    validationSchema: yup.object({
      tag: yup.string().required("Required"),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      addTagMutation.mutate(values, {
        onSuccess: () => {
          toast.success("added tag!");
          addTagForm.resetForm();
          refetchDecision();
        },
        onError: (error: unknown) => {
          // @ts-ignore
          toast.error(error.response?.data?.error || error.message);
        },
      });
    },
  });

  const deleteDecision = () => {
    if (!data) return;
    deleteDecisionMutation.mutate(data, {
      onSuccess: () => {
        toast.success("deleted decision!");
        // TODO: Revisit this in the future, main will redirect to selected team so it kind of works
        navigate(ROUTES.Main);
      },
      onError: (error: unknown) => {
        // @ts-ignore
        toast.error(error.response?.data?.error || error.message);
        // TODO: Revisit this in the future, main will redirect to selected team so it kind of works
        navigate(ROUTES.Main);
      },
    });
  };

  const updateDecisionInitialValues = {
    name: data?.decisionName || "",
    value: data?.currentValue || "",
  };

  const updateDecisionForm = useFormik({
    initialValues: updateDecisionInitialValues,
    validationSchema: updateDecisionValidationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateDecisionMutation.mutate(values, {
        onSuccess: () => {
          toast.success("Updated decision!");
          updateDecisionForm.resetForm();
          queryClient.invalidateQueries({ queryKey: [DECISIONS_QUERY] });
          refetchDecision();
          setShowUpdate(false);
        },
        onError: (error: unknown) => {
          // @ts-ignore
          toast.error(error.response?.data?.error || error.message);
        },
      });
    },
  });

  const updateDecision = (name: string, value: string) => {
    updateDecisionMutation.mutate(
      { name, value },
      {
        onSuccess: () => {
          toast.success("updated decision!");
          refetchDecision();
        },
        onError: (error: unknown) => {
          // @ts-ignore
          toast.error(error.response?.data?.error || error.message);
        },
      }
    );
  };

  const removeTag = (tag: string) =>
    removeTagMutation.mutate(
      { tag },
      {
        onSuccess: () => {
          toast.success("removed tag!");
          refetchDecision();
        },
        onError: (error: unknown) => {
          // @ts-ignore
          toast.error(error.response?.data?.error || error.message);
        },
      }
    );

  const handleCancelClick = () => {
    navigate(ROUTES.Main);
  };

  return {
    decision: data,
    isLoading,
    isRefetching,
    error,
    addTagForm,
    updateDecisionForm,
    removeTag,
    refetchDecision,
    showUpdate,
    deleteDecision,
    isLoadingHistory,
    history,
    handleUpdateDecisionClick,
    handleUpdateDecisionCancelClick,
    handleCancelClick,
  };
};

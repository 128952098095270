import React from "react";

import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Field } from "components/Field";
import { Button } from "components/ui/button";
import { useCreateTeam } from "hooks/useCreateTeam";

export const CreateProjectModal = ({ onClose }: { onClose: () => void }) => {
  const { createTeamForm, mutation, handleCancelClick } = useCreateTeam();

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle className="select-none">New Project</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>
      <div className="flex items-center space-x-2">
        <form
          onSubmit={createTeamForm.handleSubmit}
          className="grid flex-1 gap-4 mt-4"
        >
          <Field
            id="name"
            name="name"
            onChange={createTeamForm.handleChange}
            value={createTeamForm.values.name}
            label="Name"
            errors={createTeamForm.errors}
            className="!max-w-full"
          />
          <Field
            id="description"
            name="description"
            onChange={createTeamForm.handleChange}
            value={createTeamForm.values.description}
            label="Description (optional)"
            errors={createTeamForm.errors}
            className="!max-w-full"
          />

          <div className="flex justify-between w-full mt-4">
            <Button
              type="submit"
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
            >
              Continue
            </Button>

            {onClose && (
              <Button type="button" onClick={onClose} variant="outline">
                Cancel
              </Button>
            )}
          </div>
        </form>
      </div>
    </DialogContent>
  );
};

import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Loading } from "../components/Loading";
import { Tag } from "../components/Tag";
import {
  API_URL,
  Decision,
  DecisionIn,
  DECISIONS_QUERY,
  DECISIONS_GET_PATH,
  transformDecisionIn,
  TEAM_DECISIONS_GET_PATH,
  PROMPTS_GET_PATH,
  DecisionType,
} from "../types";
import { ROUTES } from "../routes";
import { Empty } from "../components/Empty";
import { Searchbox } from "components/Searchbox";
import { UiTitle } from "./Onboarding";
import { MAIN_BORDER_COLOR_TAILWIND } from "components/Variables";
import { Button } from "components/ui/button";
import { DecisionList } from "components/DecisionList";
import classNames from "classnames";
import { CreateDecisionModal } from "./project/CreateDecisionModal";
import { Dialog, DialogTrigger } from "components/ui/dialog";
import { Plus } from "@phosphor-icons/react";

const getAllDecisionTags = (decisions: Decision[]) => {
  return decisions.reduce(
    (acc, decision) => [...acc, ...decision.tags],
    [] as string[]
  );
};

const UNAUTHORIZED_STATUS_TEXT = "UNAUTHORIZED";
const TEAM_DOES_NOT_EXIST = "TEAM_DOES_NOT_EXIST";

const defaultParams = new URLSearchParams({
  page: "0",
  page_size: "50",
});

const useDecisions = (decisionType: string, teamId?: number) => {
  const decisionsUrl =
    decisionType === DecisionType.Prompt
      ? `${API_URL}${PROMPTS_GET_PATH}`
      : `${API_URL}${TEAM_DECISIONS_GET_PATH}${teamId}?${defaultParams}`;

  const navigate = useNavigate();
  const { isLoading, error, data, isRefetching } = useQuery(
    DECISIONS_QUERY,
    async () =>
      fetch(decisionsUrl, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 401) {
            throw new Error(UNAUTHORIZED_STATUS_TEXT);
          }
          if (response.status === 404 || response.status === 409) {
            throw new Error(TEAM_DOES_NOT_EXIST);
          }

          return response.json();
        })
        .then((r) => {
          const decisions = r.decisions.map((decision: DecisionIn) => {
            return transformDecisionIn(decision);
          }) as Decision[];
          return decisions;
        })
        .catch((e) => {
          if (e.message === UNAUTHORIZED_STATUS_TEXT) {
            navigate(ROUTES.Logout);
          }
          if (e.message === TEAM_DOES_NOT_EXIST) {
            navigate(ROUTES.Projects);
          }
          return e;
        }),
    { refetchOnWindowFocus: false }
  );

  const tags = getAllDecisionTags(data || []);

  return {
    decisions: (data as Decision[]) || [],
    tags,
    isLoading,
    error,
    isRefetching,
  };
};

const ClearTag = ({
  onClick,
  filterByTag,
}: {
  onClick: () => void;
  filterByTag: string | null;
}) => (
  <Tag onClick={onClick} showDelete>
    {filterByTag}
    {/* <span className="ml-2">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.877075 7.49988C0.877075 3.84219 3.84222 0.877045 7.49991 0.877045C11.1576 0.877045 14.1227 3.84219 14.1227 7.49988C14.1227 11.1575 11.1576 14.1227 7.49991 14.1227C3.84222 14.1227 0.877075 11.1575 0.877075 7.49988ZM7.49991 1.82704C4.36689 1.82704 1.82708 4.36686 1.82708 7.49988C1.82708 10.6329 4.36689 13.1727 7.49991 13.1727C10.6329 13.1727 13.1727 10.6329 13.1727 7.49988C13.1727 4.36686 10.6329 1.82704 7.49991 1.82704ZM9.85358 5.14644C10.0488 5.3417 10.0488 5.65829 9.85358 5.85355L8.20713 7.49999L9.85358 9.14644C10.0488 9.3417 10.0488 9.65829 9.85358 9.85355C9.65832 10.0488 9.34173 10.0488 9.14647 9.85355L7.50002 8.2071L5.85358 9.85355C5.65832 10.0488 5.34173 10.0488 5.14647 9.85355C4.95121 9.65829 4.95121 9.3417 5.14647 9.14644L6.79292 7.49999L5.14647 5.85355C4.95121 5.65829 4.95121 5.3417 5.14647 5.14644C5.34173 4.95118 5.65832 4.95118 5.85358 5.14644L7.50002 6.79289L9.14647 5.14644C9.34173 4.95118 9.65832 4.95118 9.85358 5.14644Z"
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
        ></path>
      </svg>
    </span> */}
  </Tag>
);

// can bet team or person decisions, add props?
export const Decisions = ({
  decisionType,
  teamId,
  showSidebar,
  toggleSidebar,
  currentProjectName,
}: {
  decisionType: string;
  teamId?: number;
  showSidebar: boolean;
  toggleSidebar: () => void;
  currentProjectName: string;
}) => {
  const { decisions, isLoading, isRefetching } = useDecisions(
    decisionType,
    teamId
  );
  const [search, setSearch] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTagClick = (tag: string) => {
    setSearchParams({ tag: tag });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const currentTag = searchParams.get("tag");

  const filteredDecisionsByTag = currentTag
    ? decisions.filter((d) => d.tags.includes(currentTag))
    : decisions;

  const filteredDecisions = search.length
    ? filteredDecisionsByTag.filter(
        (d) =>
          d.decisionName.toLowerCase().includes(search.toLowerCase()) ||
          d.currentValue.toLowerCase().includes(search.toLowerCase())
      )
    : filteredDecisionsByTag;

  const decisionsTable = decisions.length ? (
    <DecisionList
      decisions={filteredDecisions}
      loading={isRefetching}
      handleTagClick={handleTagClick}
    />
  ) : (
    <Empty
      title="Create your first Decision"
      description="Click the create new decision button to get started."
    />
  );

  return (
    <div className="flex flex-col w-full">
      <div
        className={classNames(
          "flex flex-row p-6 box-border border-b items-center",
          MAIN_BORDER_COLOR_TAILWIND
        )}
      >
        <Dialog>
          <h1 className="flex-1 font-bold cursor-default">
            {currentProjectName}
          </h1>

          {search.length > 0 && (
            <Button
              className="ml-4"
              onClick={() => setSearch("")}
              variant="outline"
              size="sm"
            >
              Clear
            </Button>
          )}

          <Searchbox
            value={search}
            handleChange={handleSearchChange}
            placeholder="Search..."
            disabled={decisions.length === 0}
            className="ml-4 max-w-xs"
          />

          <DialogTrigger asChild>
            <Button
              className="ml-4"
              onClick={() => setShowCreateModal(true)}
              size="sm"
              icon={<Plus weight="bold" />}
            >
              Create new Decision
            </Button>
          </DialogTrigger>
          {showCreateModal && (
            <CreateDecisionModal
              onClose={() => setShowCreateModal(false)}
              teamId={teamId}
            />
          )}

          <Button
            className="hidden ml-4 md:block"
            onClick={toggleSidebar}
            variant="outline"
            size="sm"
          >
            {showSidebar ? "Hide Sidebar" : "Show Sidebar"}
          </Button>
        </Dialog>
      </div>
      {currentTag && (
        <div
          className={classNames(
            "flex flex-row items-center px-6 py-2 border-b",
            MAIN_BORDER_COLOR_TAILWIND
          )}
        >
          <span className="text-gray-500 text-sm mr-2">Filtering by:</span>
          <ClearTag
            onClick={() => {
              setSearchParams({});
            }}
            filterByTag={currentTag}
          />
        </div>
      )}
      {(isLoading && <Loading />) || decisionsTable}
    </div>
  );
};

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../components/Auth";
import { ROUTES } from "../routes";

export const Logout = () => {
  const { signout, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    signout();
  }, []);

  if (!user) {
    navigate(ROUTES.Login);
  }

  return null;
};

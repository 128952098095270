import React from "react";
import styled from "styled-components";
import {
  APP_MENU_HEIGHT,
  MAIN_BORDER_COLOR,
  MAIN_BORDER_COLOR_TAILWIND,
} from "./Variables";
import classNames from "classnames";

export const MainAndSidebarLayout = styled.div`
  display: flex;
  height: 100%;
  min-height: calc(100vh - ${APP_MENU_HEIGHT}px);

  box-sizing: border-box;
  overflow-y: auto;

  /* hide scrollbars */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const MainAndSidebarLayoutMain = styled.aside`
  flex: 1;

  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::webkit-scrollbar {
  display: none
  width: 0px;
  background: transparent;
  }
`;

export const SidebarSection = ({
  children,
  className,
  title,
  subtitle,
}: {
  children: React.ReactNode;
  className?: string;
  title?: string;
  subtitle?: string;
}) => {
  return (
    <div
      className={classNames(
        "p-4 md:p-6 box-border",
        "border-b items-center",
        MAIN_BORDER_COLOR_TAILWIND,
        className
      )}
    >
      <div className="flex items-center">
        {title && (
          <h2 className="text-sm font-semibold text-zinc-900 select-none flex-1">
            {title}
          </h2>
        )}
        {subtitle && (
          <p className="text-xs text-zinc-500 select-none">{subtitle}</p>
        )}
      </div>
      {children}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import store from "store";

import { TeamMember, getDecisionType } from "types";

import { Decisions } from "../Decisions";
import {
  STORAGE_DECISION_LAB_DECISION_LIST_SIDEBAR_KEY,
  useAuth,
} from "components/Auth";
import { MainAndSidebarLayout } from "components/MainAndSidebarLayout";
import { useTeamDetails } from "hooks/useTeamDetails";
import { Sidebar } from "./Sidebar";

const useSidebar = () => {
  // parse the value from local storage
  const [showSidebar, setShowSidebar] = useState<boolean>(
    String(
      store.get(STORAGE_DECISION_LAB_DECISION_LIST_SIDEBAR_KEY)
    ).toLowerCase() === "true"
  );

  useEffect(() => {
    store.set(STORAGE_DECISION_LAB_DECISION_LIST_SIDEBAR_KEY, showSidebar);
  }, [showSidebar]);

  return { showSidebar, setShowSidebar };
};

export const ProjectDashboard = () => {
  const [search, setSearch] = useState("");
  const {
    teamMembers: fetchedTeamMembers,
    teamName,
    isRefetching,
    isLoading,
  } = useTeamDetails();
  const { teamId } = useParams();
  const { setSelectedTeam, selectedTeam, teams } = useAuth();
  const { showSidebar, setShowSidebar } = useSidebar();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  const isSelectedTeam = selectedTeam?.id === Number(teamId);

  useEffect(() => {
    setTeamMembers(fetchedTeamMembers);
  }, [fetchedTeamMembers]);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const filteredTeamMembers = teamMembers.filter(
    (member: TeamMember) =>
      member.firstName.toLowerCase().includes(search.toLowerCase()) ||
      member.lastName.toLowerCase().includes(search.toLowerCase()) ||
      member.email.toLowerCase().includes(search.toLowerCase())
  );

  const handleSetAsCurrentTeamButton = () => {
    const selectedTeam = teams.find((team) => team.id === Number(teamId));
    setSelectedTeam(selectedTeam);
  };

  return (
    <MainAndSidebarLayout className="flex-col md:flex-row h-auto md:h-full">
      <Decisions
        decisionType={getDecisionType(teamName)}
        teamId={Number(teamId)}
        showSidebar={showSidebar}
        toggleSidebar={
          showSidebar ? () => setShowSidebar(false) : () => setShowSidebar(true)
        }
        currentProjectName={teamName}
      />

      <Sidebar
        search={search}
        handleSearchChange={handleSearchChange}
        isLoading={isLoading}
        isRefetching={isRefetching}
        filteredTeamMembers={filteredTeamMembers}
        setTeamMembers={setTeamMembers}
        isSelectedTeam={isSelectedTeam}
        handleSetAsCurrentTeamButton={handleSetAsCurrentTeamButton}
        teamName={teamName}
        showSidebar={showSidebar}
      />
    </MainAndSidebarLayout>
  );
};

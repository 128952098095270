import axios from "axios";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";

import { API_URL, transformCreateTeamOut } from "types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes";

const CREATE_TEAM_PATH = "/teams/create-team";
const createTeamInitialValues = {
  name: "",
  description: "",
};

const createTeamValidationSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string(),
});

const CREATE_TEAM_QUERY = "create-team";

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Create a team using react query, post api url is /teams/create-team
  const createTeam = async (team: any) => {
    return axios.post(
      `${API_URL}${CREATE_TEAM_PATH}`,
      transformCreateTeamOut(team),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const mutation = useMutation(createTeam);

  const redirectToTeam = ({ id }: { id: number }) => {
    const redirectTo = ROUTES.Project.replace(":teamId", id.toString());
    navigate(redirectTo);
  };

  const createTeamForm = useFormik({
    initialValues: createTeamInitialValues,
    validationSchema: createTeamValidationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      mutation.mutate(values, {
        onSuccess: (response) => {
          toast.success("New project created!");
          createTeamForm.resetForm();
          queryClient.invalidateQueries({ queryKey: [CREATE_TEAM_QUERY] });

          redirectToTeam(response.data);
        },
        onError: (error: unknown) => {
          // @ts-ignore
          toast.error(error.response?.data?.error || error.message);
        },
      });
    },
  });

  const handleCancelClick = () => {
    navigate(ROUTES.Projects);
  };

  return { createTeamForm, mutation, handleCancelClick };
};

import React from "react";
import classNames from "classnames";
import { Empty } from "./Empty";
import { Loading } from "./Loading";

export const Table = ({
  isLoading,
  isRefetching,
  columnNames,
  data,
  renderRow,
  noResultsTitle,
  noResultsDescription,
}: {
  isLoading?: boolean;
  isRefetching?: boolean;
  columnNames?: string[];
  data: any[];
  renderRow: (row: any) => React.ReactNode;
  noResultsTitle?: string;
  noResultsDescription?: string;
}) => {
  if (data.length === 0 && !isRefetching && !isLoading) {
    return <Empty title={noResultsTitle} description={noResultsDescription} />;
  }

  return (
    <>
      <table
        className={classNames("w-full text-sm text-left text-zinc-700 ", {
          isRefetching: "opacity-50	animate-pulse",
        })}
      >
        {columnNames && (
          <thead>
            <tr>
              {columnNames.map((name) => (
                <th
                  scope="col"
                  className="px-6 py-4 font-semibold text-sm text-zinc-900"
                  key={name}
                >
                  {name}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>{data.map((d) => renderRow(d))}</tbody>
      </table>
      {isLoading && <Loading className="inline-block w-full py-12" />}
    </>
  );
};

import React, { useEffect, useState } from "react";
import store from "store";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useLocation, Navigate } from "react-router-dom";
import { CredentialResponse } from "@react-oauth/google";

import { ROUTES } from "../routes";
import { useTeams } from "hooks/useTeams";
import { Team } from "types";

export const STORAGE_DECISION_LAB_TEAMS_KEY = "dlteams";
export const STORAGE_DECISION_LAB_SESSION_KEY = "session";
export const STORAGE_DECISION_LAB_DECISION_LIST_SIDEBAR_KEY =
  "dldecisionlistsidebar";
const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

// https://developers.google.com/identity/protocols/oauth2
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(store.get(STORAGE_DECISION_LAB_SESSION_KEY));

  const signout = () => {
    store.remove(STORAGE_DECISION_LAB_SESSION_KEY);
    // Initially I didn't want to remove the team from local storage but
    // sometimes teams don't exist anymore, and I don't want to assume
    // that you want to login with the same team again.
    store.remove(STORAGE_DECISION_LAB_TEAMS_KEY);
    setUser(null);
  };

  const {
    teams,
    isLoading: teamsLoading,
    hasOneTeam,
    hasManyTeams,
  } = useTeams({
    signout,
  });
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(
    store.get(STORAGE_DECISION_LAB_TEAMS_KEY) || null
  );

  useEffect(() => {
    if (selectedTeam) {
      store.set(STORAGE_DECISION_LAB_TEAMS_KEY, selectedTeam);
    }
  }, [selectedTeam]);

  const loginWithGoogle = async (credentials: CredentialResponse) => {
    setLoading(true);
    const response = await axios.post(
      `${API_BASE_URL}/v1/login/callback`,
      credentials,
      {
        withCredentials: true,
      }
    );

    if (response.status === 200 && credentials.credential) {
      const user = jwt_decode(credentials.credential);
      setUser(user);
      store.set(STORAGE_DECISION_LAB_SESSION_KEY, user);
    }

    setLoading(false);

    return response;
  };

  const value = {
    user,
    loginWithGoogle,
    signout,
    loading,
    teams,
    teamsLoading,
    hasOneTeam,
    hasManyTeams,
    selectedTeam,
    setSelectedTeam,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

type User = {
  email: string;
  family_name: string;
  given_name: string;
  name: string;
  picture: string;
  sub: string;
};

interface AuthContextType {
  user?: User;
  loginWithGoogle: (credentials: CredentialResponse) => void;
  signout: () => void;
  loading: boolean;
  teams: any[];
  teamsLoading: boolean;
  hasOneTeam: boolean;
  hasManyTeams: boolean;
  selectedTeam: Team | null;
  setSelectedTeam: (team: Team) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth?.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={ROUTES.Login} state={{ from: location }} replace />;
  }

  return children;
};

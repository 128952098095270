import React from "react";
import classNames from "classnames";

export const Tag = ({
  children,
  showDelete,
  handleDelete,
  onClick,
  isLight,
  isPositive,
  isSmall,
  className,
  style,
}: {
  children: React.ReactNode;
  showDelete?: boolean;
  handleDelete?: () => void;
  onClick?: (e?: React.MouseEvent) => void;
  isLight?: boolean;
  isPositive?: boolean;
  isSmall?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const foregroundColor = "text-purple-700";
  const backgroundColor = "bg-purple-100";

  const Element = onClick ? "button" : "span";

  return (
    <Element
      onClick={onClick}
      className={classNames(
        `whitespace-nowrap last-of-type:mr-0 rounded-full px-4 py-1.5 text-sm font-medium cursor-default select-none flex items-center w-fit`,
        foregroundColor,
        backgroundColor,
        { "cursor-pointer": onClick },
        { "pr-2": !!showDelete },
        { "hover:bg-purple-200": !!onClick || !!showDelete },
        { "bg-slate-100 text-slate-500": isLight },
        { "bg-green-100 text-green-500": isPositive },
        { "text-[9px] px-1 py-0.5": isSmall },
        className
      )}
      style={style}
    >
      {children}
      {showDelete && (
        <button
          className={classNames(
            `ml-2 inline-block p-0.5 rounded-full`,
            backgroundColor
          )}
          onClick={handleDelete}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.877075 7.49988C0.877075 3.84219 3.84222 0.877045 7.49991 0.877045C11.1576 0.877045 14.1227 3.84219 14.1227 7.49988C14.1227 11.1575 11.1576 14.1227 7.49991 14.1227C3.84222 14.1227 0.877075 11.1575 0.877075 7.49988ZM7.49991 1.82704C4.36689 1.82704 1.82708 4.36686 1.82708 7.49988C1.82708 10.6329 4.36689 13.1727 7.49991 13.1727C10.6329 13.1727 13.1727 10.6329 13.1727 7.49988C13.1727 4.36686 10.6329 1.82704 7.49991 1.82704ZM9.85358 5.14644C10.0488 5.3417 10.0488 5.65829 9.85358 5.85355L8.20713 7.49999L9.85358 9.14644C10.0488 9.3417 10.0488 9.65829 9.85358 9.85355C9.65832 10.0488 9.34173 10.0488 9.14647 9.85355L7.50002 8.2071L5.85358 9.85355C5.65832 10.0488 5.34173 10.0488 5.14647 9.85355C4.95121 9.65829 4.95121 9.3417 5.14647 9.14644L6.79292 7.49999L5.14647 5.85355C4.95121 5.65829 4.95121 5.3417 5.14647 5.14644C5.34173 4.95118 5.65832 4.95118 5.85358 5.14644L7.50002 6.79289L9.14647 5.14644C9.34173 4.95118 9.65832 4.95118 9.85358 5.14644Z"
              fill="currentColor"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      )}
    </Element>
  );
};

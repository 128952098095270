import React from "react";
import * as yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { DateTime } from "luxon";

import { useDecision as useDecisionDecision } from "decisionlab";

import { API_URL, DECISIONS_PUT_PATH, Decision } from "types";

import { getDate } from "pages/DecisionDetail";

import { ContextMenu } from "./ContextMenu";
import { SidebarSection } from "./MainAndSidebarLayout";
import { AddCommentForm } from "./AddDecisionComment";

type AddCommentPayload = { comment: string };

export const useDecisionComments = ({
  decisionId,
  refetchDecision,
}: {
  decisionId: number;
  refetchDecision: () => void;
}) => {
  const addCommentCall = async (payload: AddCommentPayload) => {
    return axios.post(
      `${API_URL}${DECISIONS_PUT_PATH}/${decisionId}/comment`,
      payload,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const removeCommentCall = async (decision: { comment: string }) => {
    return axios.delete(`${API_URL}/v1/comment/${decisionId}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      data: { comments: [decision.comment] },
    });
  };

  const addCommentMutation = useMutation(addCommentCall);
  const removeCommentMutation = useMutation(removeCommentCall);

  const addCommentForm = useFormik({
    initialValues: { comment: "" },
    validationSchema: yup.object({
      comment: yup.string().required("Required"),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      addCommentMutation.mutate(values, {
        onSuccess: () => {
          toast.success("added comment!");
          addCommentForm.resetForm();
          refetchDecision();
        },
        onError: (error: unknown) => {
          // @ts-ignore
          toast.error(error.response?.data?.error || error.message);
        },
      });
    },
  });

  const removeComment = (comment: string) =>
    removeCommentMutation.mutate(
      { comment },
      {
        onSuccess: () => {
          toast.success("removed comment!");
          refetchDecision();
        },
        onError: (error: unknown) => {
          // @ts-ignore
          toast.error(error.response?.data?.error || error.message);
        },
      }
    );

  return {
    addCommentForm,
    removeComment,
  };
};

const DecisionCommentsList = ({
  decision,
  refetchDecision,
}: {
  decision: Decision;
  refetchDecision: () => void;
}) => {
  const { removeComment } = useDecisionComments({
    decisionId: decision.id,
    refetchDecision,
  });
  const showDeleteButton = useDecisionDecision("show_delete_button") === true;

  return (
    <ul className="">
      {decision.comments.map((comment, i) => {
        const commentDate = getDate(comment.createdAt);
        const isSameYear = DateTime.now().hasSame(commentDate, "year");

        return (
          <li
            className="py-3 sm:py-4 border-b"
            key={`${commentDate.toUnixInteger()}-${i}`}
          >
            <div className="flex items-start space-x-4">
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900">
                  {comment.comment}
                </p>
                <p className="text-sm text-zinc-600 truncate select-none mt-1">
                  <span className="text-zinc-400">by </span>
                  {comment.author}
                  <span className="text-zinc-400 text-xs">
                    {` on `}
                    {commentDate.toFormat("MMM dd")}
                    {!isSameYear && `, ${commentDate.toFormat("yyyy")}`}
                    {` at `}
                    {commentDate.toFormat("t")}
                  </span>
                </p>
              </div>

              {showDeleteButton && (
                <ContextMenu
                  actions={[
                    {
                      label: "Delete comment",
                      onClick: () => removeComment(comment.comment),
                      isNegative: true,
                    },
                  ]}
                />
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export const DecisionComments = ({
  decision,
  refetchDecision,
}: {
  decision?: Decision;
  refetchDecision: () => void;
}) => {
  return (
    <SidebarSection title="Comments" subtitle="Newest first">
      {decision && (
        <>
          {Boolean(decision?.comments.length) && (
            <DecisionCommentsList
              decision={decision}
              refetchDecision={refetchDecision}
            />
          )}
          <AddCommentForm
            decision={decision}
            refetchDecision={refetchDecision}
          />
        </>
      )}
    </SidebarSection>
  );
};

import React, { useState } from "react";
import { ClipboardCopyIcon } from "@radix-ui/react-icons";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";

interface CopyToClipboardProps {
  text: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ text }) => {
  const [copied, setCopied] = useState(false);
  const { toast } = useToast();

  const handleCopyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Copied to clipboard",
      });

      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <Button
      variant="outline"
      size="iconSmall"
      onClick={handleCopyClick}
      title={copied ? "Copied!" : "Copy to Clipboard"}
      className="ml-2"
    >
      <ClipboardCopyIcon />
    </Button>
  );
};

export default CopyToClipboard;

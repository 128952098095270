import React from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { useTeamDetails } from "hooks/useTeamDetails";
import { Field } from "./Field";
import { Button } from "./ui/button";

export const AddMemberModal = ({
  onSuccessCallback,
  onCancelClick,
}: {
  onSuccessCallback?: () => void;
  onCancelClick?: () => void;
}) => {
  const { createTeamMemberForm, createMemberMutation } = useTeamDetails({
    onSuccessCallback,
  });
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle className="select-none">Add new member</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>
      <div className="flex items-center space-x-2">
        <form
          onSubmit={createTeamMemberForm.handleSubmit}
          className="grid flex-1 gap-2 mt-4"
        >
          <Field
            id="email"
            name="email"
            onChange={createTeamMemberForm.handleChange}
            value={createTeamMemberForm.values.email}
            label="User email"
            errors={createTeamMemberForm.errors}
            placeholder="example@domain.com"
            className="!max-w-full"
          />

          <div className="flex justify-between w-full mt-4">
            <Button
              type="submit"
              disabled={createMemberMutation.isLoading}
              loading={createMemberMutation.isLoading}
              style={{ margin: "0px" }}
            >
              Send Invite
            </Button>

            {onCancelClick && (
              <Button
                type="button"
                style={{ margin: "0px" }}
                onClick={onCancelClick}
                variant="outline"
              >
                Cancel
              </Button>
            )}
          </div>
          <p className="mt-4 text-sm text-neutral-500 cursor-default select-none">
            Invited users will receive an email with a link to join your team.
          </p>
        </form>
      </div>
    </DialogContent>
  );
};

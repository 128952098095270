import React from "react";
import { ExitIcon } from "@radix-ui/react-icons";

import { Button } from "components";
import { useAuth } from "components/Auth";
import { ROUTES } from "routes";

const Header = ({
  title,
  subtitle,
  profilePicture,
}: {
  title: string;
  subtitle?: string;
  profilePicture?: string;
}) => (
  <header aria-label="Page Header" className="bg-gray-50">
    <div className="mx-auto container py-16">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="text-center sm:text-left">
          <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
            {title}
          </h1>
          {subtitle && (
            <p className="mt-1 text-xs font-medium text-gray-600">{subtitle}</p>
          )}

          <Button goTo={ROUTES.Logout} className="mt-4" icon={<ExitIcon />}>
            Logout
          </Button>
        </div>

        <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
          {profilePicture && (
            <img
              alt={title}
              src={profilePicture}
              className="h-16 w-16 rounded-lg object-cover shadow-sm"
            />
          )}
        </div>
      </div>
    </div>
  </header>
);


export const Account = () => {
  const { user } = useAuth();
  const name = `${user?.given_name} ${user?.family_name}`;

  return (
    <div>
      <Header
        title={name}
        subtitle={user?.email}
        profilePicture={user?.picture}
      />
    </div>
  );
};

import React from "react";
import { Button } from "components/Button";
import { FormikValues } from "formik";

import { Decision } from "../types";

import { Field } from "./Field";
import { CommitIcon } from "@radix-ui/react-icons";

export const AddDecisionTagForm = ({
  decision,
  form,
  className,
}: {
  decision?: Decision;
  form: FormikValues;
  className?: string;
}) => {
  const decisionTags = decision?.tags || [];
  const renderDecisionTags = decisionTags.map((tag) => <p>{tag}</p>);

  return (
    <form onSubmit={form.handleSubmit} className={className}>
      <Field
        id="tag"
        name="tag"
        onChange={form.handleChange}
        value={form.values.tag}
        label="Create new tag"
        errors={form.errors}
      />
      <Button type="submit" icon={<CommitIcon />} className="mt-4">
        Add tag
      </Button>
    </form>
  );
};

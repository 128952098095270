import React, { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

import { API_URL, TeamMember, TeamMemberStatus } from "types";

export const NOT_A_TEAM_MEMBER_FIRST_NAME = "Not a decisionlab user";
const GET_TEAM_QUERY = "get-team";

const CREATE_TEAM_PATH = "teams/invite-user";
const DELETE_USER_PATH = "teams/remove-user";
const CANCEL_INVITE_PATH = "teams/cancel-invitation";

export const useTeamMember = ({
  user,
  setTeamMembers,
}: {
  user: TeamMember;
  setTeamMembers: React.Dispatch<React.SetStateAction<TeamMember[]>>;
}) => {
  const [showMore, setShowMore] = useState(false);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
  const queryClient = useQueryClient();
  const isPending =
    user.status === TeamMemberStatus.Pending ||
    user.firstName === NOT_A_TEAM_MEMBER_FIRST_NAME;

  const isPositive = user.status === TeamMemberStatus.Active;

  const { teamId = "" } = useParams();
  const bodyToCancelOrDelete = {
    team_id: teamId,
    email: user.email,
  };

  const handleRemoveClick = async () => {
    try {
      setTeamMembers((prevMembers) =>
        prevMembers.filter((member) => member.email !== user.email)
      );

      const endpoint = isPending ? CANCEL_INVITE_PATH : DELETE_USER_PATH;
      const response = await fetch(`${API_URL}${endpoint}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyToCancelOrDelete),
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
      // refresh user list
      toast.success("User removed from team.");
      queryClient.invalidateQueries([GET_TEAM_QUERY, teamId]);
    } catch (error: any) {
      console.error({ error });
      toast.error(error.message);
      setTeamMembers((prevMembers) => [...prevMembers, user]);
    }
  };

  return {
    user,
    showMore,
    setShowMore,
    isPending,
    isPositive,
    handleRemoveClick,
    showRemoveMemberModal,
    setShowRemoveMemberModal,
  };
};

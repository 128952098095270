import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LightningBoltIcon } from "@radix-ui/react-icons";

import { useAuth } from "components/Auth";

import { ROUTES } from "routes";
import { Team } from "types";

export const Gateway = () => {
  const navigate = useNavigate();

  const {
    loginWithGoogle,
    user,
    loading,
    teamsLoading,
    teams,
    hasOneTeam,
    selectedTeam,
    hasManyTeams,
    setSelectedTeam,
  } = useAuth();

  useEffect(() => {
    if (user && !teamsLoading) {
      // user is logged in and their selected team is on its teams list
      if (teams.find((team: Team) => team.id === selectedTeam?.id)) {
        navigate(`${ROUTES.Projects}/${selectedTeam?.id}`);
        return;
      }
      // user is logged in and has a selected team but it's not on its teams list
      else if (selectedTeam?.id || hasManyTeams) {
        navigate(ROUTES.Projects);
        return;
      }
      if (hasOneTeam) {
        // auto select one team
        const lastTeamId = teams[teams.length - 1].id;
        setSelectedTeam(teams.find((team: Team) => team.id === lastTeamId));
        navigate(`${ROUTES.Projects}/${lastTeamId}`);
        return;
      } else {
        navigate(ROUTES.Projects);
        return;
      }
    }
    if (!user && !loading) {
      navigate(ROUTES.Login);
      return;
    }
  }, [user, teamsLoading]);

  return <LightningBoltIcon />;
};

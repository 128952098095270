import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "components/Auth";

import * as yup from "yup";

import { API_URL, transformTeamMemberIn, TeamMember } from "types";

interface TeamDetails {
  members: TeamMember[];
  teamName: string;
}

const listTeamMembersPath = (teamId: string) =>
  `teams/${teamId}/get-team-members`;

const createTeamMemberInitialValues = {
  email: "",
};

const createTeamMemberValidationSchema = yup.object({
  email: yup.string().email("Please enter a valid email.").required("Required"),
});

const GET_TEAM_QUERY = "get-team";

const CREATE_TEAM_PATH = "teams/invite-user";
const DELETE_USER_PATH = "teams/remove-user";
const CANCEL_INVITE_PATH = "teams/cancel-invitation";

export const useTeamDetails = ({
  onSuccessCallback,
}: {
  onSuccessCallback?: () => void;
} = {}) => {
  const { teamId: teamIdParam = "" } = useParams();
  const teamId = parseInt(teamIdParam, 10);
  const queryClient = useQueryClient();
  const { setSelectedTeam } = useAuth();

  const {
    isLoading,
    error,
    data: teamDetailsData,
    isRefetching,
  } = useQuery<TeamDetails>(
    [GET_TEAM_QUERY, teamId],
    async () =>
      fetch(`${API_URL}${listTeamMembersPath(teamId.toString())}`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((r) => {
          const team = {
            members: r.data.map((member: any) => transformTeamMemberIn(member)),
            teamName: r.team_name,
          };
          setSelectedTeam({ id: teamId, name: team.teamName, description: "" });
          return team;
        })
        .catch((e) => {
          console.error({ e });
          toast.error(e.message);
          throw e;
        }),
    { refetchOnWindowFocus: false }
  );
  const localTransformAddUser = (member: any) => {
    return {
      email: member.email,
      team_id: teamId,
    };
  };

  // Create a team using react query, post api url is /teams/invite-user
  const createTeamMember = async (member: any) => {
    return axios.post(
      `${API_URL}${CREATE_TEAM_PATH}`,
      localTransformAddUser(member),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const createMemberMutation = useMutation(createTeamMember);

  const createTeamMemberForm = useFormik({
    initialValues: createTeamMemberInitialValues,
    validationSchema: createTeamMemberValidationSchema,
    validateOnChange: false,
    onSubmit: (values: any) => {
      createMemberMutation.mutate(values, {
        onSuccess: (response) => {
          toast.success("An invite has been sent to the email.");
          createTeamMemberForm.resetForm();
          // refresh user list
          queryClient.invalidateQueries([GET_TEAM_QUERY, teamId]);

          if (onSuccessCallback) {
            onSuccessCallback();
          }
        },
        onError: (error: unknown) => {
          // @ts-ignore
          toast.error(error.response?.data?.error || error.message);
        },
      });
    },
  });

  return {
    teamMembers: teamDetailsData?.members || [],
    teamName: teamDetailsData?.teamName || "",
    isLoading,
    error,
    isRefetching,
    createTeamMemberForm,
    createMemberMutation,
  };
};

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import styled from "styled-components";
import toast from "react-hot-toast";

import { useAuth } from "components/Auth";
import { ROUTES } from "../routes";
import { Loading } from "components/Loading";
import { UiTitle, StyledDecisionLabUiDisclaimer } from "./Onboarding";

const LoginLayout = ({
  children,
  loading,
}: {
  children: React.ReactNode;
  loading?: boolean;
}) => {
  const showTitle = !loading;
  const showTermsAndConditionsParagraph = !loading;
  return (
    <div className="flex flex-col items-center justify-center h-screen w-screen">
      {showTitle && <UiTitle>Log in to DecisionLab</UiTitle>}

      <form action="#" className="mt-8 grid grid-cols-6 gap-6">
        <div className="col-span-6">
          {children}
          {showTermsAndConditionsParagraph && (
            <StyledDecisionLabUiDisclaimer className="mt-4">
              By creating an account, you agree to our
              <br />
              <a href="#" className="text-gray-700 underline px-1">
                terms and conditions
              </a>{" "}
              and
              <a href="#" className="text-gray-700 underline pl-1">
                privacy policy
              </a>
              .
            </StyledDecisionLabUiDisclaimer>
          )}
        </div>
      </form>
    </div>
  );
};

export const Login = () => {
  const { loginWithGoogle, user, loading } = useAuth();
  const navigate = useNavigate();

  const onError = () => {
    toast.error("there was an error");
  };

  const goToGateway = () => {
    if (user?.email) {
      navigate(ROUTES.Main);
    }
  };

  useEffect(() => {
    goToGateway();
  }, []);

  useEffect(() => {
    goToGateway();
  }, [user]);

  return (
    <LoginLayout loading={loading}>
      {loading ? (
        <Loading />
      ) : (
        <GoogleLogin onSuccess={loginWithGoogle} onError={onError} />
      )}
    </LoginLayout>
  );
};
